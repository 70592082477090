import React from "react"
import Layout from '../components/layout'
import ReadMore from "../components/btnReadMore"
import { PageWrapper, ThanksWrapper, H1, Paragraph } from '../style/common/common'

const ThanksPage = ({ location }) => {
  return (
    <Layout
      location={location}
    >
      <PageWrapper>
        <ThanksWrapper>
          <H1>Thanks!</H1>
          <Paragraph>お問い合せを受け付けました。<br />ありがとうございます。<br />のちほど担当者よりご連絡申し上げます。</Paragraph>
          <ReadMore to="/" label="トップにもどる"/>
        </ThanksWrapper>
      </PageWrapper>
    </Layout>
  )
}

export default ThanksPage;
